// 突出普遍价值（SOUV）声明材料
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'JCJGMC',
                    label: '监测机构名称',
                    minWidth: 120,
                    showoverflowtooltip: true
                }, {
                    prop: 'FZR',
                    label: '负责人',
                    minWidth: 60
                }, {
                    prop: 'RYZS',
                    label: '人员总数',
                    minWidth: 80
                }, {
                    prop: 'JGCLRQ',
                    label: '机构成立日期',
                    minWidth: 120,
                    showoverflowtooltip: true
                },
                SHZT,
                DJZT
            ],
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'buttonExport',
            showMethod: 'showExportBtn',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        selectData: []
    }
};
export default selfData;